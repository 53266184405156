import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import {
  Link,
  Page,
  Container,
  Typography,
  Button,
  Time,
  SEO,
  SEOProps,
  PostList,
} from '~/components'
import IndexLayout from '~/layouts'

import { PostEdges } from '../hooks/usePosts'

export interface TagsProps {
  pageContext: {
    tag: string
  }
  data: {
    allMdx: {
      totalCount: number
      edges: PostEdges
      // edges: {
      //   node: {
      //     fields: {
      //       slug: string
      //     }
      //     frontmatter: {
      //       title: string
      //       date: string
      //     }
      //   }
      // }[]
    }
  }
}

const TagList = styled.ul`
  margin: ${(p) => p.theme.spacings[5]}rem 0;
`

const TagHeader = styled(Typography)`
  text-transform: capitalize;
`

const Tags: React.FC<TagsProps> = ({ pageContext, data }) => {
  const { tag } = pageContext
  const { edges, totalCount } = data.allMdx
  const capitalizedTag = tag.trim().replace(/^\w/, (c) => c.toUpperCase())
  const tagHeader = `[${totalCount}] ${capitalizedTag}`

  const seo: SEOProps = {
    title: tagHeader,
    path: `/tags/${tag}`,
    article: false,
  }

  return (
    <IndexLayout>
      <SEO
        title={seo.title}
        description={seo.description}
        image={seo.image}
        path={seo.path}
        article={seo.article}
      />
      <Page>
        <Container>
          <TagHeader variant="h2">{tagHeader}</TagHeader>
          <TagList>
            <PostList posts={edges} />
            {/* {edges.map(({ node }) => {
              const { slug } = node.fields
              const { title, date } = node.frontmatter
              return (
                <li key={slug}>
                  <Time>{date}</Time>
                  <Link to={slug}>{title}</Link>
                </li>
              )
            })} */}
          </TagList>
          <Link to="/tags" style={{ textDecoration: 'none' }}>
            <Button variant="primarySm">All tags</Button>
          </Link>
        </Container>
      </Page>
    </IndexLayout>
  )
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] }, draft: { eq: false } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMM DD, YYYY")
          }
        }
      }
    }
  }
`
